import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import {
  Wrapper,
  Subtitle,
  H2,
  P,
  Seo,
  OurPartners,
  Spinner,
} from '../../components'
import { Filters, GridClients } from './components'
import './our-clients-style.scss'
import { device } from '../../theme/device'

const TitlePageWrapper = styled((props) => <Wrapper {...props} />)` 

  @media ${device.tablet} { 
    max-width: 500px
  }
  @media ${device.desktop} { 
  }
`

const WhoWeServeView = ({
  t,
  loading,
  loadingHighLine,
  partners,
  partnersFiltered,
  filterSelected,
  onClick,
  data,
}) => {
  if (loading || loadingHighLine) {
    return <Spinner loading={loading} />
  }

  return (
    <React.Fragment>
      <Seo title={t('server.subtitle')}/>
      <Wrapper flexDirectionD="column">

        <Wrapper
          flexDirectionD="column"
          displayM="box"
          paddingM="32px 0 80px"
          paddingD="32px 0">
          <Wrapper
            widthD="1920px"
            flexDirectionD="column"
          >
            <Wrapper
              widthD="750px"
              marginM=" 0 0 62px"
              flexDirectionD="column"
              style={{ overflow: 'hidden' }}
            >
              <Subtitle>{t('server.subtitle')}</Subtitle>
              <TitlePageWrapper
                style={{ textAlign: 'center' }}
                paddingM=" 0 25px"
                marginM="0 0 16px 0"
                widthD="475px"
                marginD="12px 0 32px 0">
                <H2
                  fontSize="2xll"
                  lineHeight="4xll"
                  fontSizeD="4xl"
                  lineHeightD="6xl"
                >{t('server.title')}</H2>
              </TitlePageWrapper>
              <Wrapper

                paddingM="0 20px 40px">
                <P
                  style={{ textAlign: 'center' }}
                  lineHeight={'3xl'}
                  lineHeightD={'3xl'}
                >
                  {_.get(data, 'acf.header_section.section_highlight')}
                </P>

              </Wrapper>
              <Filters
                filterSelected={filterSelected}
                onClick={onClick}
              />
            </Wrapper>
            <Wrapper widthD="1240px">

              <GridClients partnersDetail={partnersFiltered} t={t}/>
            </Wrapper>
            <div
              className="contianer-parthers-who">
              <OurPartners
                title={t('server.ours')}
                t={t}
                partners={partners}/>
            </div>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </React.Fragment>

  )
}

export default WhoWeServeView
