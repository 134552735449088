import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import WhoWeServeView from './our-clients-view'
import { usePartners } from '../../hooks/use-partners'
import { makeRequest, changelanguages } from '../../helpers'

const WhoWeServeController = () => {
  const [data, setData] = useState([])
  const [loadingHighLine, setLoadingHighLine] = useState(true)
  const [filterSelected, setFilterSelected] = useState('all')
  const [partnersDetailed, setPartnersDetailed] = useState([])
  const [partnersFiltered, setPartnersFiltered] = useState([])
  const { t, i18n } = useTranslation()
  const { loading, partners, getPartnersHasDetail } = usePartners()

  const getWhoWeAre = useCallback(async () => {
    setLoadingHighLine(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'pages',
      params: {
        lang: changelanguages(i18n.language),
        slug: 'our-clients',
      },
    }).then((resp) => {
      setData(resp.data[0])
      setLoadingHighLine(false)
    })
  }, [])

  useEffect(() => {
    getWhoWeAre()
  }, [i18n.language])

  const onClickFilters = (filter) => {
    setFilterSelected(filter)
  }
  useEffect(() => {
    if (filterSelected === 'all') {
      setPartnersFiltered(partnersDetailed)
    } else {
      const filtereingPartners = _.filter(partnersDetailed, (p) => (_.includes(_.toLower(p.services), filterSelected)))
      setPartnersFiltered(filtereingPartners)
    }
  }, [filterSelected])

  useEffect(() => {
    const partDetail = getPartnersHasDetail(partners)
    setPartnersDetailed(partDetail)
    setPartnersFiltered(partDetail)
  }, [partners])

  const viewProps = {
    data,
    t,
    loading,
    loadingHighLine,
    partners,
    partnersFiltered,
    filterSelected,
    onClick: onClickFilters,
  }
  return (
    <WhoWeServeView {...viewProps } />
  )
}

export default WhoWeServeController
