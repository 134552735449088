import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Wrapper, Button } from '../../../../components'
import { useWindowDimensions } from '../../../../helpers'

const Filters = ({
  onClick,
  filterSelected,

}) => {
  const { t, i18n } = useTranslation()
  const filters = ['all', 'cargo', 'passenger']
  const size = useWindowDimensions()
  const width = _.get(size, 'width', 0)
  return (
    <Wrapper
      flexDirectionM="row"
      marginD="64px 0 90px"
      paddingM="0 20px"
    >

      {
        _.map(filters, (filter) => (
          <Wrapper
            onClick={() => onClick(filter)}
            key={filter}
            marginM="0 4%"
            widthM="auto"
            style={{
              width: width <= 768 ? 'auto' : '100%',
            }}
            widthD="200px">
            <Button
              name={`${filterSelected} ${filter}`}
              style={{
                padding: width <= 768 ? '0 15px' : 0,
                fontSize: width <= 768 ? '12px' : '14px',
                height: width <= 768 ? '40px' : '50px',
                backgroundColor: filter === filterSelected ? '#E20613' : 'white',
                color: filter === filterSelected ? 'white' : '#E20613',
              }}>
              <p style={{ whiteSpace: 'nowrap' }}>{t(`server.buttons.${filter}`)}</p>
            </Button>
          </Wrapper>

        ))
      }

    </Wrapper>
  )
}

export default Filters
