/* eslint-disable no-plusplus */
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import './grid-clients-style.scss'
import { Link } from 'gatsby'
import IconArrow from '../../../../assets/icons/TAM-icon-left-arrow.svg'
import iconCruiser from '../../../../assets/images/TAM_cruise_icon_grey.png'
import iconAirplane from '../../../../assets/images/TAM_airline_icon_grey.png'

const GridClients = ({
  t,
  partnersDetail,
}) => {
  const range = _.range(12)
  const [hasClass, setHasClass] = useState([])

  const getIfHasClass = () => {
    let s = 1
    const arrayNumbers = []
    for (let i = 0; i < range.length; i++) {
      if (i === s) {
        s += 3
      } else {
        arrayNumbers.push(i)
      }
    }
    setHasClass(arrayNumbers)
  }

  useEffect(() => {
    getIfHasClass()
  }, [])
  return (
    <div className="grid-clients">
      {
        _.map(partnersDetail, (partnerDetail, idx) => (
          <Link to={`/our-clients/${partnerDetail.slug}/`} key={partnerDetail.id} className="grid-clients-link">
            <div className={`grid-clients-box ${_.includes(hasClass, idx) && 'has-class'}`} >
              <div className="grid-clients-hover">
                <p>{_.get(partnerDetail, 'title.rendered')}</p>
                <span>
                  {_.includes(partnerDetail.services, 'Cargo') ? ' Cargo ' : ''}
                  {_.includes(partnerDetail.services, 'Passenger') && _.includes(partnerDetail.services, 'Cargo') ? '|' : ''}
                  {_.includes(partnerDetail.services, 'Passenger') ? ' Passenger ' : ''}
                </span>
                <IconArrow width="30" fill="#fff" />
              </div>
              <div className="grid-clients-item">
                {
                  _.includes(partnerDetail.services, 'airline') && (
                    <div className="grid-clients-icons">
                      <img src={iconAirplane} alt="airplane" />
                      <p>Airlines</p>
                    </div>

                  )
                }
                {
                  _.includes(partnerDetail.services, 'cruise') && (
                    <div className="grid-clients-icons">
                      <img src={iconCruiser} alt="cruise" />
                      <p>Cruise</p>
                    </div>

                  )
                }
                <img className="grid-clients-image" src={_.get(partnerDetail, 'featured_image.url')} />
                <span className="grid-clients-span">{t('general.buttons.learn')}</span>
              </div>
            </div>
          </Link>
        ))
      }

    </div>
  )
}

export default GridClients
